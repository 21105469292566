<script setup lang="ts">
import BRFCSLayout from '@/Layouts/BRFCSLayout.vue'
import { computed } from 'vue'

const props = defineProps<{
    message?: string;
    status?: number;
}>()

const message = computed(() => {
    if (props.status === 404) {
        return 'Page not found'
    }

    return 'An error occurred'
})

</script>

<template>
    <BRFCSLayout>
        <section class="w-error__content-container">
            <img src="/images/404.png" alt="404" class="w-error__lead-image" />
            <div class="w-error__content-container">
                <h2 class="w-error__title">{{ message }}</h2>
            </div>
        </section>
    </BRFCSLayout>
</template>

<style scoped lang="scss">
@use "#/utilities/mixins" as m;

.w-error {

    &__lead-media {
        width: 100%;
    }

    &__lead-image {
        width: 100%;
        height: 70vh;
        object-fit: cover;
    }

    &__content-container {
        max-width: 1080px;
        margin: -8rem auto 0;
        padding: 3.2rem;
        background: var(--background-color);
        z-index: var(--z-index-1);
        position: relative;
        border-radius: .8rem;
        display: flex;
        flex-direction: column;
    }

    &__meta-container {
        @include m.sm;
        display: flex;
        flex-wrap: wrap;
        gap: .2rem;
        margin-bottom: .8rem;
    }

    &__title {
        @include m.h1-bold;
    }

    &__excerpt {
        @include m.h5;
        margin-bottom: 1.6rem;
    }

    &__author {
        @include m.sm-bold;
    }

    &__num-comments {
        @include m.sm-bold;
        margin-top: 1.6rem;
        color: var(--g-color-primary);
    }

    &__body {
        position: relative;
        margin-top: 2.4rem;
        padding-top: 4.8rem;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: .2rem;
            background: var(--g-gradient-primary-secondary);
        }
    }

    @media (width < m.$desktop) {
        &__lead-image {
            height: auto;
            aspect-ratio: 16 / 9;
        }

        &__content-container {
            margin-top: -1.2rem;
            padding: 2.4rem;
        }
    }

    @media (width < m.$tablet) {
        &__content-container {
            padding: 1.6rem;
        }

        &__body {
            margin-top: 1.2rem;
            padding-top: 2.4rem;
        }
    }
}

.w-comments {
    max-width: 72rem;
    margin: 5.6rem auto;
    padding: 3.2rem;
    background: var(--background-color);
    border-radius: .8rem;

    &__title {
        @include m.h2-bold;
        margin-bottom: .8rem;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
    }
}

</style>
